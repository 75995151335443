.overview h2{
    color: #fd7019;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;

}
.image-item{
    height: 270px;
    width: 100%;
    
    overflow: hidden;
}
.image-item img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.higher p{
    color: #676767;
    font-size: 18px;
    font-weight: 450;
    line-height: 1.4;
    margin-bottom: 16px;
    
}
.instructor{
    background-color: #fff4ed;
    padding: 25px;
}
.duration{
background-color: #f8fdff;
padding: 25px;
}
.demand{
    background-color: #f9f2ff;
    padding: 25px;

}
.mock{
    background-color: #fff4ed;
    padding: 25px;
}
.daily{
background-color: #f8fdff;
padding: 25px;
}
.revision{
background-color: #f9f2ff;
padding: 25px;
}
.booking{
    background-color: #fff4ed;
    padding: 25px;
}
.overview-1 h2{
    text-align: center;
    color: #fd7019;
    font-weight: 700;
}