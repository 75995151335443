.readmore{
    color: rgb(0, 0, 0);
}
.readmore:hover{
    color: #f2c40c;
}
.recent-news{
    /* border: 1px solid rgb(191 184 184);
    box-shadow: 4px 4px 1px rgb(221 221 221); */
    padding: 20px;
}
.recent-news h2 {
font-weight: 600;
color: #f2c40c;
}
.recent-news ul li a {
    /* list-style-type: none; */
    color: black;
    font-size: 17px;
    line-height: 35px;
    cursor:pointer;
}
.recent-news ul li:hover{
    color: #f2c40c !important;
}
.recent-news ul li a:hover{
    color: #f2c40c !important;
}
.block-content{
    background: #777b85;
    color: #fff;
    padding: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.date{
    font-size: 14px;
    color: #686874;
}
.latest-img{
    height: 200px;
    object-fit: cover;
}
.latest-img img{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
