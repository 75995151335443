:root {
    --color-two: #f2c40c;
}



 .blog-card-image{
    width: 100%;
    height: 300px;
    overflow: hidden;
}
@media only screen and (max-width:1150px){
    .blog-card-image{
        width: 100%;
    }
}
.blog-card-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* .card-details{
    background-color: var();
    padding: 10px;
} */
.can-mar a{
    color:#212529;
}
.can-mar a:hover{
    color: #f2c40c;
}
.blog-banner{
    position: relative;
    bottom: 225px;
    padding: 5px 60px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border-radius: 10px;
    margin-bottom: -225px;

}
.read-more{
    background-color: #f2c40c;
    padding: 5px 8px;
    border: none;
    border-radius: 5px;
}
.read-more:hover{
    background-color:#4a8237 !important;
}
 



.blog-header h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2;
    color: var(--color-two);
}

.subtitle {
    font-size: 18px;
}

.card-title {
    color: var(--color-two);
    font-size: 20px;
    font-weight: 600;
}

.paragraph {
    font-size: 15px;
    color: #454545;
}

.btn-read {
    border: none;
    background: none;
    border-bottom: 1px solid #000;
    font-size: 15px;
}

.by {
    font-size: 11px;
    padding: 3px;
}

.on {
    font-size: 11px;
    border-left: 1px solid #000;
    padding: 3px;
}

.minutes {
    font-size: 11px;
    border-left: 1px solid #000;
    padding: 3px;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    /* height: 500px; */
    height: 31rem;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.image-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px 15px 0 0;
}

.image-item {
    height: 220px;
    width: 100%;
    overflow: hidden;
}