.card-college{
    font-size: 20px;
    line-height: 30px;
    color: #282828;
}
.card-scholorship{
    font-size: 20px;
    line-height: 30px;
    
    margin-top: -10px;
}
.card-college-para{
    font-size: 20px;
    line-height: 30px;
}
.card-image{
   text-align: right;
}
.quote-detail {
    color: #282828;
    padding: 3px;
    font-size: 15px;
}