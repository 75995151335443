.test-img{
        height: 300px;
        overflow: hidden;  
}
.test-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.test-prep{
    /* text-align: center;
    border: 1px solid rgb(202 202 202); */
    padding: 10px;
}
.test-prep ul li a{
color: black;
font-weight: 500;
}
.test-prep ul li a:hover{
    color: #f2c40c;
}
.test-prep ul li{
list-style-type: none;
line-height: 35pxpx;
}
