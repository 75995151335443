@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");

* {
  padding: 0px;
  margin: 0px;
  font-family: "Roboto Slab", serif;
}

ul {
  padding: 0px !important;
}

p {
  font-family: var(--header);
}

a {
  text-decoration: none;
  cursor: pointer;
  font-family: var(--header);
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-family: var(--header);
}

:root {
  --header: "Roboto", sans-serif;
  --color-two: #f2c40c;
  --color-one: #4a8237;
  --color-three: #c1c4ce;
  --color-four: #fcdad4;
  --color-five: #69b44f;
  --color-six: #797a7c;
  --w-color: #fff;
  --p-color: #777b85;
}

.main-section-part {
  padding: 40px 0px 0px 0px;
}

.container {
  /* max-width: 1240px; */
}

.content-section {
  width: 100%;
  display: block;
  /* padding: 120px 0; */
}

/* -- Navbar -- */

.topbar {
  width: 100%;
  height: 92px;
  line-height: 60px;
  background-color: var(--color-five);
  color: var(--w-color);
  text-align: center;
  position: relative;
  z-index: 1;
  margin-bottom: -24px;
  position: relative;
}
.topbar-li {
  padding-top: 14px !important;
}
.topbar .container {
  display: flex;
  flex-wrap: wrap;
}

.topbar .container .tagline {
  font-weight: 600;
}

/* .topbar .container .social-media {
  margin: 0 auto;
  padding: 0;
} */

.topbar .container .social-media li {
  float: left;
  margin: 0 8px;
  padding: 0;
  list-style: none;
}

.topbar .container .social-media li a {
  color: var(--w-color);
  float: left;
  font-size: 12px;
}

.topbar .container .social-media li a:hover {
  color: var(--color-two);
}

.topbar .container .phone {
  margin-left: auto;
  padding-top: 14px;
}

.topbar .container .phone a {
  text-decoration: none;
  color: var(--w-color);
}

.topbar .container .phone img {
  height: 20px;
  margin-top: -3px;
  margin-right: 7px;
}

.navbar {
  z-index: 999;
  width: 65%;
  margin: auto;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border-radius: 4px;
}
@media only screen and (max-width: 500px) {
  .navbar {
    margin-top: 15px;
  }
}
.navbar-nav .dropdown-menu {
  position: relative;
}
.navbar .container .logo {
  margin-right: auto;
  padding: 8px 0;
}

.navbar .container .logo a {
  float: left;
}

.navbar .container .logo a img {
  height: 68px;
}

img {
  max-width: 100%;
}

.navbar-nav li a {
  /* float: left; */
  /* padding: 0 15px; */
  /* height: 100px; */
  /* line-height: 100px; */
  color: var(--color-six);
  font-weight: 600;
  /* position: relative; */
  background-color: #f8f9fa;
}
@media only screen and (max-width: 1200px) {
  .navbar-nav li a {
    float: left;
  }
}

.navbar-nav li a :hover {
  color: var(--color-two) !important;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.nav-item {
  font-size: 14px;
  margin-right: 0.5rem;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: var(--color-two);
  background-color: #ffffff;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000000;
}

/* .navbar {
    width: 100%;
    max-width: 1380px;
    margin: 0 auto;
    padding: 0;
    background: var(--w-color);
    box-shadow: 0 15px 30px rgb(0 0 0 / 10%), 0 8px 30px rgb(0 0 0 / 10%);
    z-index: 2;
}

.navbar .container {
    position: relative;
}


/* 
.navbar .container .menu-main-menu-container {
    margin: 0 auto;
} */

/* .navbar .container .menu-horizontal li {
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    z-index: 2;
    position: relative;
} */

/* 

.nav-link {
    display: block;
}

.navbar .container .menu-horizontal li .dropdown {
    min-width: 190px;
    position: absolute;
    left: -10px;
    top: 100%;
    margin: 0;
    padding: 10px 0;
    margin-top: 5px;
    background: var(--w-color);
    box-shadow: 0 15px 30px rgb(0 0 0 / 10%), 0 8px 30px rgb(0 0 0 / 10%);
    opacity: 0;
    visibility: hidden;
}

.navbar .container .menu-horizontal li .dropdown li a:hover {
    visibility: visible;
}

.navbar .container .menu-horizontal li .dropdown li {
    width: 100%;
    float: left;
    margin: 0;
    padding: 0;
}

.navbar .container .menu-horizontal li .dropdown li a {
    width: 100%;
    height: 40px;
    line-height: 48px;
    float: left;
    padding: 0 25px;
    white-space: nowrap;
}

.navbar .container .language {
    margin-left: auto;
    order: 1;
    margin: 0;
    padding: 0;
}

.navbar .container .language li {
    float: left;
    margin: 0 5px;
    padding: 0;
    list-style: none;
}

.navbar .container .language li a {
    float: left;
    color: var(--color-one);
    font-weight: 600;
}

.navbar .container .search-btn {
    height: 50px;
    line-height: 50px;
    margin-left: 10px;
    padding-left: 20px;
    border-left: 1px solid #eaebee;
    order: 3;
    cursor: pointer;
    font-size: 17px;
    color: var(--color-one);
}

.navbar .container .sandwich-btn {
    margin-right: 0;
    margin-left: auto;
    order: 2;
}

  */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  /* opacity: 0; */
  max-height: 0px;
  /* visibility: hidden; */
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

@media only screen and (min-width: 1200px) {
  .dropdown:hover .dropdown-content {
    display: block;
    opacity: 1;
    /* visibility: visible; */
    max-height: 500px;
    transition: all 1.5s ease;
  }
}

@media only screen and (max-width: 1198px) {
  .dropdown .dropdown-content {
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
  }
}

.dropdown-list {
  /* border-bottom: 1px solid #cecece; */
}

/* .dropdown-toggle::after {
    visibility: visible;
} */

/* -- Main Banner -- */
@media only screen and (max-width: 1000px) {
  .dashboard-banner {
    display: none;
  }
}
.carousel-img {
  height: 600px;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 800px) {
  .carousel-img {
    height: 300px;
  }
}

.carousel-control-prev {
  top: 150px;
}

.carousel-control-next {
  top: 150px;
}

.carousel-img img {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.card-overlay {
  bottom: 78px !important;
  margin-bottom: -115px;
}

@media screen and (max-width: 450px) {
  .card-overlay {
    margin-bottom: -120px;
  }
}
@media only screen and (max-width: 1200px) {
  .carousel-inner {
    bottom: 328px !important;
    margin-bottom: -315px !important;
  }
}

.carousel-inner {
  bottom: 78px;
  margin-bottom: -78px;
}

.overlay {
  color: var(--color-one);
  width: 100%;
  height: 100%;
  position: absolute;
}

/* .carousel-img {
    height: 700px;
} */

.carousel-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  text-align: right;
}

.carousel-container h6 {
  font-size: 30px;
  font-weight: 700;
  font-style: italic;
  font-family: "Playfair Display", serif;
  font-family: var(--header);
}

.carousel-container h2 {
  font-size: 64px;
  font-weight: 800;
  margin-bottom: 20px;
  font-family: var(--header);
}

.carousel-container h2 span {
  color: var(--color-two);
  font-family: var(--header);
}

.carousel-container p {
  max-width: 600px;
  display: inline-block;
  margin-bottom: 40px;
  font-family: var(--header);
}

/* .carousel-container a {
  display: inline-block;
  height: 60px;
  line-height: 58px;
  border: 1px solid var(--w-color);
  color: #fff;
  padding: 0 44px;
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
  background-color: transparent;
  font-family: var(--header);
} */

/* .carousel-container a:hover{
  border:  1px solid var(--color-two);
  color: var(--color-two);
} */

.carousel-caption {
  position: absolute;
  right: 5%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

/* -- Second Banner -- */

/* .main .vc_row .vc_row {
  margin-left: -15px !important;
  margin-right: -15px !important;
  background-color: var(--color-three);
} */

/* .vc-custom {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  background-color: var(--color-three);
} */

/* .vc_column_container {
  padding-left: 0;
  padding-right: 0;

} */

/* .vc_col-sm-2 {
  width: 16.66666667%;
  float: left;
} */

/* .vc_column_container > .vc_column-inner {
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
} */

.partner {
  width: 100%;
  display: block;
  margin: 0;
  padding: 30px;
  opacity: 0.7;
  transition: 0.25s ease-in-out;
  background-color: var(--color-three);
}

.partner > .img {
  height: auto;
  max-width: 100%;
}

/* -- Training -- */

.section-title {
  width: 100%;
  padding-bottom: 15px;
}

.section-title span {
  display: block;
  font-size: 18px;
  font-weight: 800;
  /* padding-top: 40px; */
}

.section-title h2 {
  display: block;
  font-weight: 700;
  font-family: var(--header);
  color: var(--color-two);
  margin-bottom: 20px;
  letter-spacing: 1.5px;
}

.section-title h6 {
  font-size: 19px;
  color: var(--p-color);
  line-height: 30px;
  margin-bottom: 0;
  font-weight: 400;
  font-family: var(--header);
}

.blurb-content-card {
  /* text-align: center; */
  padding-bottom: 20px;
  overflow: hidden;
}

.blurb-img {
  width: 100%;
  float: left;
  height: 300px;
  margin-bottom: 20px;
  overflow: hidden;
}

.blurb-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blurb-main-img {
  height: 100%;
  width: 100%;
}

.blurb-container h4 {
  font-weight: 600;
  color: var(--color-two);
  font-size: 20px;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-family: var(--header);
  text-align: left;
}

.blurb-container p {
  margin: 0;
  color: var(--p-color);
  font-family: var(--header);
  text-align: left;
}

.blurb-container {
  position: relative;
}

.training-paragraph {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.study-abroad-btn {
  border: none;
  background-color: var(--color-five);
  color: white;
  padding: 10px 38px;
  margin: 20px 0px;
  border-radius: 4px;
}

.zoom:hover {
}

/* .zoom {
    padding: 50px;
    background-color: green;
    transition: transform .2s;
    width: 200px;
    height: 200px;
    margin: 0 auto;
  } */

.zoom:hover {
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: translateY(-15px);
  /* border: 1px solid #BFBFBF; */
  background-color: white;
  opacity: 0.7;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  overflow: hidden;
}

.zoom {
  transition: transform 0.5s ease-in-out !important;
}

/* .zoom:hover .blurb-container h4,
.zoom:hover .blurb-container p {
    padding: 0px 10px;
} */

/*    Training class */

.training-name h4 {
  color: var(--color-one);
  font-weight: 800;
  margin-bottom: 20px;
  margin-top: 15px;
  font-family: var(--header);
}

.training-name span {
  margin-top: 15px;
  margin-bottom: 20px;
  color: var(--p-color);
  font-family: var(--header);
}

.training-name img {
  height: 150px;
  width: 100%;
  object-fit: cover;
}

.training-name {
  padding: 2px 20px 30px;
  border: 1px solid #ededed;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  outline: transparent solid 1px;
  font-family: var(--header);
}

.training-name:hover {
  box-shadow: 0px 0px 8px 0px #e7dada;
}

.training-heading {
  height: 40px;
}

.read-button {
  /* text-decoration: underline; */
  color: var(--color-one);
  position: absolute;
  right: 0px;
  bottom: 1px;
}

/* Training class ends */

/* About us  */

.about-button {
  padding: 10px 10px;
  background-color: var(--color-five) !important;
  color: white;
  float: left;
}

#about .section-title h2 {
  color: var(--color-two);
  letter-spacing: 1.5px;
}

.about-us-main {
  padding: 0px !important;
}

.about-us-para p {
  font-size: 20px;
  font-weight: 700;
  font-family: var(--header);
  letter-spacing: 1.5px;
  text-align: center;
}

.about_para {
  font-size: 18px;
  letter-spacing: 1.5px;
}

.about-us-num {
  font-size: 25px !important;
}

.about-section {
  /* text-align: center; */
  margin: auto;
  color: var(--w-color) !important;
  padding: 100px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 999 !important;
  color: #fff !important;
}

.about-section h2 {
  z-index: 999 !important;
}

@media only screen and (min-width: 300px) {
  .about-section {
    padding: 50px;
  }
}

.aboutus-banner {
  width: 100%;
  height: 600px;
}

.aboutus-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.parallax {
  background-color: #000000;
  background-image: url(../src/Components/assets/images/about.jpg);
  min-height: 475px;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
@media only screen and (max-width: 500px) {
  .parallax {
    min-height: 550px;
  }
}

/* Testimonials    */

.testimonial-main .section-title h2 {
  color: var(--color-two);
  letter-spacing: 1.5px;
}

.about-container {
  padding: 80px 0px;
}

.testy {
  background-color: var(--color-one);
}

/* .section-title{
  padding: 0px 0px 21px 0px;
} */

.section-title-head {
  width: fit-content;
  padding-bottom: 12px;
  border-bottom: 5px solid var(--color-one);
  font-family: var(--header);
}

/* .about-first-row{
  margin: 40px 0px;
}
.text-left
{
  float: left;
}
.text-right
{
  float:right;
} */

/* .facts-number{
  color: #34b7a7;
  font-size: 48px;
} */

/* .facts-num{
  text-align: center;
} */

.testimonial {
  text-align: center;
  /* padding: 30px 0px; */
}

.testimonial-img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.testimonial-card h3 {
  font-size: 20px;
  font-weight: bold;
  font-size: 14px;
  color: #999;
  margin: 15px 0 15px 0;
  color: rgb(255, 255, 255);
  font-family: var(--header);
}

.testimonial-card h4 {
  font-style: italic;
  font-size: 14px;
  color: #999;
  margin: 0 0 15px 0;
  font-family: var(--header);
}

.testimonial-card p {
  margin: 0 auto 15px auto;
  font-family: var(--header);
  color: var(--w-color);
  padding: 0px 50px;
}

.quote {
  color: var(--w-color);
  padding: 3px;
  font-size: 10px;
}

.carousel-control-prev,
.carousel-control-next {
  /* display: none; */
}

/* Schedule section */

.schedule-banner {
  color: white;
  padding: 40px;
  background-color: var(--color-one);
}

@media screen and (max-width: 800px) {
  .schedule-banner {
    padding: 20px;
  }
}

.schedule-banner1 {
  padding: 40px 0px;
}

.schedule-banner label {
  margin-bottom: 13px;
  padding-left: 5px;
  color: rgb(250, 250, 250);
  font-size: 15px;
  font-weight: 600;
}

@media screen and (max-width: 970px) {
  .schedule-banner label {
    font-size: 10px;
  }
}

.checked-btn:checked {
  background-color: var(--color-five);
}

.sec-input {
  padding: 17px 17px;
  width: 100%;
  margin-bottom: 25px;
}

.schedule-banner input:focus {
  outline: none;
}

.schedule-btn {
  background-color: var(--color-two);
  color: #000;
  /* padding: 10px 65px; */
  border: none;
  padding: 14px 38px;
  border-radius: 0 !important;
}

.schedule-btn:hover {
  background-color: var(--color-five);
  color: white;
}

.appointment-component {
  color: var(--w-color);
}

.inquiry-now {
  color: var(--color-two);
}

/* news section */

.news-section h4 {
  color: var(--color-two);
  font-weight: 600;
  font-family: var(--header);
}

.news-section small {
  color: var(--color-one);
  margin-bottom: 20px;
  display: block;
  font-weight: 600;
  font-family: var(--header);
}

.news-section p {
  color: var(--color-three);
  font-family: var(--header);
}

.news-section {
  padding: 20px;
  border: 1px solid #ededed;
}

.news-section:hover {
  box-shadow: 0px 0px 8px 0px #e7dada;
  cursor: pointer;
}

.news-section i {
  border: 1px solid #d6d0d0;
  border-radius: 47%;
  font-size: 20px;
  color: #31313c;
  padding: 3px;
}

.news-event-img {
  height: 373px;
  width: 100%;
  object-fit: cover;
}

/* footer */

footer {
  background-color: var(--color-one);
  color: rgb(255, 255, 255);
  padding-top: 30px;
  margin-top: 30px;
}

@media only screen and (max-width: 750px) {
  footer {
    text-align: center;
  }
}

.footer-main ul li a {
  text-decoration: none;
  color: white;
  font-family: var(--header);
}

.footer-main ul li a:hover {
  text-decoration: underline;
}

.footer-main ul li {
  list-style-type: none;
  margin-bottom: 15px;
}

.footer-icon ul li a {
  /* color: rgb(255, 255, 255); */
  text-decoration: none;
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f3f3f3;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
}

.footer-icon ul li a i {
  color: #414141;
  font-size: 16px;
}

.quick-links ul li {
  padding-left: 10px !important;
}

.footer-icon ul li a:hover {
  /* color: var(--color-two); */
  background: #414141;
}

.footer-icon ul li a:hover i {
  color: #f3f3f3 !important;
  font-size: 14px;
}

.footer-icon ul li {
  display: inline-block;
  margin-top: 10px;
}

.footer-header {
  color: #fff;
  font-weight: 700;
  font-size: 28px;
  margin: 10px 0 10px 10px;
}

footer a {
  color: white;
}

.rights {
  justify-content: center;
}

.overlay {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}

.overlay:after {
  content: "";

  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.card-overlay {
  position: relative;
  /* z-index: 1; */
}

.card-overlay:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: var(--color-three);
  opacity: 0.5;
}

/* Abroad Details page */
.section-image {
  height: 450px;
  width: 100%;
  overflow: hidden;
}

.section-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.section-study {
  position: relative;
  bottom: 220px;
  text-align: center;
  color: white;
}

.section-study h1 {
  font-weight: 700;
  font-size: 45px;
  font-family: var(--header);
}

.section-headline {
  text-align: right;
}

.section-headline span {
  color: var(--color-two);
}

.section-headline h2 {
  font-size: 40px;
}

.section-details p {
  font-size: 18px;
}

.intro {
  color: var(--color-two);
  text-transform: uppercase;
}

.can-mar {
  margin-top: 30px;
}

.learn-more:hover {
  color: var(--color-two);
}

.top-broder {
  border-bottom: 6px solid var(--color-two);
  width: 39%;
  margin-bottom: 15px;
}

.accordian {
  position: relative;
  bottom: 219px;
  margin: 0px 100px;
  margin-bottom: -148px;
}

@media only screen and (max-width: 500px) {
  .accordian {
    margin: 0px 20px;
  }
}

.accordian strong {
  font-size: 26px;
}

.accordion-body {
  padding: 1rem 1.25rem;
  font-size: 19px;
}

.number {
  background: var(--color-two);
  color: white;
  margin: 0px 9px;
  height: auto;
  /* padding: 14px 0px 46px 0px; */
  padding: 5px 19px;
}

/* -- Banner -- */

.banner {
  position: relative;
  overflow: hidden;
  background: #fff;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./Components/assets/images/canada.jpg") no-repeat;
  background-size: cover;
  background-position: center bottom;
  min-height: 550px;
}

.banner .block {
  padding-top: 150px;
}

.banner .block h1 {
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -1.2px;
  text-transform: capitalize;
  color: #fff;
}

@media (max-width: 480px) {
  .banner .block h1 {
    font-size: 38px;
    line-height: 20px;
    font-size: 30px;
  }

  .banner {
    min-height: 450px;
    /* background: #fff !important; */
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./Components/assets/images/canada.jpg") no-repeat;
    background-size: cover;
    background-position: center bottom;
  }
}

@media (max-width: 400px) {
  .banner .block h1 {
    font-size: 28px;
    line-height: 10px;
    font-size: 30px;
  }

  .banner {
    min-height: 450px;
    /* background: #fff !important; */
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./Components/assets/images/canada.jpg") no-repeat;
    background-size: cover;
    background-position: center bottom;
  }
}

@media (max-width: 768px) {
  .banner .block h1 {
    font-size: 56px;
    line-height: 40px;
    font-size: 30px;
  }

  .banner {
    /* background: #fff !important; */
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./Components/assets/images/canada.jpg") no-repeat;
    background-size: cover;
    background-position: center bottom;
  }
}

@media (max-width: 992px) {
  .banner {
    /* background: #fff !important; */
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./Components/assets/images/canada.jpg") no-repeat;
    background-size: cover;
    background-position: center bottom;
  }
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: var(--color-five);
  text-decoration: none;
  font-weight: 600;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.nav-link:focus,
.nav-link:hover {
  color: var(--color-two) !important;
}

/* .card-details {
    background: var(--color-four)
} */

/* contactform */

.contact-form input {
  width: 100%;
  padding: 8px;
  border: none;
  background: #e0e8ef;
  border-radius: 4px;
}

.contact-form input:focus {
  outline: none;
}

.contact-form label {
  margin: 9px 0px;
}
@media only screen and (min-width: 768px) and (max-width: 912px) {
  .contact-form label {
    font-size: 11px;
  }
}

.contact-form select {
  width: 100%;
  padding: 9px;
  border: none;
  background: #e0e8ef;
  border-radius: 4px;
}

.contact-form select:focus {
  outline: none;
}

.contact-form button {
  border: none;
  background-color: var(--color-five);
  color: white;
  padding: 10px 38px;
  margin: 20px 0px;
}

.nameplc input:placeholder {
  color: red;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.logo-nav {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 12px 0;
}
.logo-nav img {
  height: 68px !important;
  width: 68px !important;
  object-fit: cover;
}
