.btn-main:hover{
    background-color: #1c7429 !important;
    /* background-color: #000 !important; */
    color: #fff;
}
.error-message {
    color: red !important;
    font-size: 10px;
    font-weight: 600;
}
.appointment-section-field{
    /* background-color:#69b44f ; */
    padding: 15px 60px;
}