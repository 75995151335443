.image-sections img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin-bottom: 15px;
}
.image-sections{
    width: 100%;
    height: 350px;
    overflow: hidden;


}

.abroaddetails-para{
    
    margin: 0px 14.3906px 0px 28.7969px;
    padding: 0px;
  
    text-align: left;
    
    color: rgb(0, 0, 0);
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-weight: 400;
    letter-spacing: normal;
    orphans: 2;
    text-indent: 0px;
    text-transform: none;
    white-space: normal;
    widows: 2;
    word-spacing: 0px;
    -webkit-text-stroke-width: 0px;
    background-color: rgb(255, 255, 255);
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
}