p{
    text-align: justify;
}

.who_we_are {
    position: relative;
}

.content_middle {
    display: flex;
    justify-content: center;
    align-items: center;
}

 .content_header {
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  
} 

.content_header h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2;
}

.content_header p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 16px;
    color: #676767;
}

.who_we_are .second-row {
    padding: 60px 40px;
    background: #fff3ec;
    color: #676767;
}

.mission-section {
    margin: 80px 0px;
}

.about-img {
    height: 373px;
    width: 100%;
}

.about-main-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.about-header h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2;
    margin: 20px 0;
}

.about-content p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 16px;
    color: #676767;
}

.vision-section {
    margin-bottom: 80px;
}
.contact_details{
    margin-top: 30px !important;
}

.contact_info_title {
    color: #ed5a00;
    margin: 0 0 16px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;

}
.contact_info {
    list-style: none;
    display: block;
    font-size: 18px;
    color: #676767 !important;
    font-weight: 400;
    padding-left: 0 !important;
}
.contact_info a{
    color: #676767 !important;
}
.col-content{
    padding: 0px 30px;
}
